import { Outlet } from '@remix-run/react';
import { useTitle } from 'react-use';

import { AdminView } from '../pages/Admin/AdminView';
import { makeTitle } from '../utils/common';

export function OrganizationsShellRoute() {
  useTitle(makeTitle('Organizations'));
  return (
    <AdminView notificationCenter>
      <Outlet />
    </AdminView>
  );
}

export const Component = OrganizationsShellRoute;

export function clientLoader() {
  return null;
}
